import '../styles/videography.css';
import LeftArrow from '../resources/Arrow Left Border.png';
import RightArrow from '../resources/Arrow Right Border.png';
import BottomContent from '../components/bottomContent';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import DroneVideo from '../resources/drone_video.mp4';
import CommunityDays from '../resources/images/events/communitydays-3.jpg';
import VideoEditing from '../resources/images/videoEdit.png';
import Carousel from '../components/carousel';
function Videography() {
    const [curVideo, setCurVideo] = useState(3);
    const [fadeOut, setFadeOut] = useState(false);
    const [fadeIn, setFadeIn] = useState(false);

    const videoUrls = [
        'https://www.youtube.com/embed/RQUw1GI__0E',
        'https://www.youtube.com/embed/vFmmAT0zwWI',
        'https://www.youtube.com/embed/N41nhhQo7F0',
        'https://www.youtube.com/embed/Q0B_QAqR-KA',
        'https://www.youtube.com/embed/lyMkERA1fn8',
        'https://www.youtube.com/embed/UeyXx5sOcAI',
        'https://www.youtube.com/embed/knYfbwdAoEw',
        'https://www.youtube.com/embed/swsM-s-QEos'
    ];
    const videoTitles = [
        'STARTING MASON AYRES CREATIVE',
        "WOMEN'S POWER EVENT",
        'MAST ROOFING: PROJECT PEACE OF MIND',
        'MAST ROOFING - HONEST REVIEWS',
        'RESTORATION TECHNICAL INSTITUTE - DTS',
        'ALAN SHINKUS FISHING - LAKE CHAMPLAIN',
        'VIBE FOR A CURE',
        'THE COTTAGE BAKERY AND CAFE'

    ];
    const videoDescriptions = [
        'A quick vlog about kicking off my business, and talking about life. First long form video on the Mason Ayres Creative Youtube channel.',
        "Just a small compilation shooting b-roll for <a href='https://picturesbytodd.com/' target='_blank'>Pictures By Todd</a> at the Women's POWER event at Lincoln Financial Field - at the Philadelphia Eagles' First Trust Bank Club Suite.",
        "For the past 13 years, <a href='https://mastsroofing.com/' target='_blank'>Mast Roofing & Construction</a> has given away a free roof to a person in need within the local community. During employment as their Brand Ambassador I captured the whole event, as well as participated in the installation!",
        "Getting the scoop on the clients of <a href='https://mastsroofing.com/' target='_blank'>Mast Roofing & Construction</a> to see whether or not their standards have been met for a reputable roofing company. Conducted a simple 5 minute long interview to hear their thoughts!",
        "Sharing a new technological advancement for the restoration industry, in 2023, <a href='https://rtilearning.com/' target='_blank'>Restoration Technical Institute</a> unveiled their new 'Digital Training Solution.'",
        "Alan Shinkus Fishing, a Semi Pro bass-fisherman takes on Lake Champlain in Vermont looking for some winning fish.",
        "A nonprofit event shoot for a cause dedicated to curing ALS, through the power of movement and dance. Covered both photography & videography at the event. Shot at <a href='https://www.vibedancesport.com/' target='_blank'>Vibe DanceSport Philadelphia</a> on Blackmagic Pocket Cinema 6k.",
        "A complimentary highlight reel for a website client showcasing their amazing baked goods. Located in Kutztown, Pennsylvania."
    ];

    const handleArrowClick = (direction) => {
        setFadeOut(true);
        setTimeout(() => {
            setCurVideo(prevIndex => {
                if (direction === 'left') {
                    return prevIndex === 0 ? videoUrls.length - 1 : prevIndex - 1;
                } else {
                    return prevIndex === videoUrls.length - 1 ? 0 : prevIndex + 1;
                }
            });
            setFadeOut(false);
            setFadeIn(true);
            setTimeout(() => {
                setFadeIn(false);
            }, 500); // Duration of the fade-in
        }, 500); // Duration of the fade-out
    };

    return (
        <div className="videography">
        <Helmet>
        <title>Videography Services | Mason Ayres Creative</title>
        <meta name="description" content="Creating high-quality, memorable videos and performing video-related services for businesses and individuals with something to showcase." />  
        <meta property="og:description" content="Creating high-quality, memorable videos and performing video-related services for businesses and individuals with something to showcase." />  
        <meta name="keywords" content="Videography, custom video, video editing, drone video, aerial videography, video marketing, youtube video, social media video, Mason Ayres Creative" />  
        <meta property="og:keywords" content="Videography, custom video, video editing, drone video, aerial videography, video marketing, youtube video, social media video, Mason Ayres Creative" />  
        <meta name="title" content="Videography Services | Mason Ayres Creative"/>
        <meta property="og:title" content="Videography Services | Mason Ayres Creative"/>
        </Helmet>
            <h1 class="vidHeader">VIDEOGRAPHY - MASON AYRES CREATIVE</h1>
             <h2 className={`videotitle ${fadeOut ? 'fade-out' : ''} ${fadeIn ? 'fade-in' : ''}`}>{videoTitles[curVideo]}</h2>
            <div className='videoSection'>
                <div className='Vidarrow leftArrow' onClick={() => handleArrowClick('left')}><img src={LeftArrow} alt='left arrow' /></div>
                <iframe className='VideoMain' src={videoUrls[curVideo]} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                <div className='Vidarrow rightArrow' onClick={() => handleArrowClick('right')}><img src={RightArrow} alt='right arrow' /></div>
            </div>
           
            <p className={`videodescription ${fadeOut ? 'fade-out' : ''} ${fadeIn ? 'fade-in' : ''}`} dangerouslySetInnerHTML={{ __html: videoDescriptions[curVideo] }}></p>
            <section className="vidArticle twoColumn whiteo">
            <h2 className='videotitle2 flexHeader'>VIDEOGRAPHY SERVICES</h2>
            <div className="horizontalLine"></div>
            <div className='columnItem textLeft'>
            <p><strong>Lights, camera... you get the rest.</strong> Looking for a dedicated videographer to help you with your project?</p>
            <p> What's unique about Mason Ayres Creative when it comes to video? There are a large amount of skilled videographers out there that can help with your needs. Unfortunately, a lot of them operate under very strict conditions, or have a modus operandi that doesn't fit with your particular vision.
                We're flexible, adaptable and courageous, and aren't afraid of getting our hands dirty. Now I say "we" but I mean mostly myself, and a couple of other trusted associates.
            </p>
            </div>
            
            <img className="columnItem columnImage" alt="camera" src="https://teemusphoto.com/wp-content/uploads/2019/02/filmmaking.png"></img>           
 </section>
            <section className="vidArticle">
            <h2 className='videotitle2'>DRONE VIDEOGRAPHY</h2>
            <p>Get professional, awe inspiring drone footage for your next video.</p> 
            <video className="twoColumn droneVideo" muted autoPlay loop > <source src={DroneVideo} type="video/mp4"></source></video>   
            </section>
            <section className="vidArticle oneColumn whiteo">
            <h2 className='videotitle2 flexHeader'>EVENTS</h2>
            <div className="horizCenter oneColumnItem"></div>
            <div className='oneColumnItem textCenter'>
            <p>Looking for the perfect videographer for your event? Let us listen to your needs. Whether they're large and theatrical, or small and intimate, we have the resources to get the job done.</p>
            </div>
            
            <img className="oneColumnItem" alt="community days pageant" src={CommunityDays}></img>           
 </section>
 <section className="vidArticle twoColumn whiteo">
            <h2 className='videotitle2 flexHeader'>VIDEO EDITING SERVICES</h2>
            <div className="horizontalLine"></div>
            <div className='columnItem textLeft'>
            <p>Video editing may not be the most glamorous thing to think about when shooting video, but it's a necessary and intergral part of every videography project from social media marketing, to feature-length films. With over 10 years of video-editing experience, we can make your productions shine. Great video editing can be the difference between 100 views, and 1 million.</p>
            <p>There's several packages available to work on your next edit, just let us know what you need and we'll get you there.</p>
            </div>
            
            <img className="columnItem columnImage" alt="video editing" src={VideoEditing}></img>           
 </section>
 <Carousel />
            <BottomContent />
            
        </div>
    );
};

export default Videography;