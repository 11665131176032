import '../styles/standard.css';
import BottomContent from '../components/bottomContent';
import webProcess1 from '../resources/images/WebProcess-01.svg';
import webProcess2 from '../resources/images/WebProcess-02.svg';
import webProcess3 from '../resources/images/WebProcess-03.svg';
import Markwood from '../resources/companyLogos/Markwood.svg';
import OwlsRoost from '../resources/companyLogos/OwlsRoost.png';
import MYKU from '../resources/images/MKYU_screenshot.png';
import { Helmet } from 'react-helmet';
import WebVideo from '../resources/webDesign_Banner.mp4';
import WebVideoMobile from '../resources/mobile_webDesign_Banner.mp4';
import JakobWiosk from '../resources/images/JakobSite.png';
function WebDesign(){
    return(
        <div className="WebDesign">
            <Helmet>
                <title>Web Design Services | Mason Ayres Creative</title>
                <meta name="description" content="Custom web design services tailored for your business. We create responsive, modern websites to enhance your online presence." key="description" />
                <meta name="keywords" content="web design, custom websites, responsive design, Mason Ayres Creative" />
                <meta property="og:title" content="Web Design Services | Mason Ayres Creative" />
                <meta property="og:description" content="Looking for custom web design? Mason Ayres Creative offers modern, responsive web design services." />
                <meta property="og:url" content="https://masonayres.design/webdesign" />
            </Helmet>
            <div className='content1'>
                <header className='PageHeader'>
                <video src={WebVideo} className="HeaderVideo desktopHero" type="video/mp4" autoPlay muted loop></video>
                <video src={WebVideoMobile} className="HeaderVideo mobileHero" type="video/mp4" autoPlay muted loop></video>
                <h1>CRISPY,<br className="mobileHero"></br> BEAUTIFUL WEB DESIGN.</h1>
                </header>
                <section className="imageSection">
                    <img className="captionLeft" src={webProcess3} alt="caption"></img>
                    <p>Navigating the world of Web Design when you don't know what to look for is a <strong>confusing,</strong> and frankly <strong>terrifying</strong> experience. Take the guesswork out of it. With us, you won't have to worry about whether or not we're some scammer who doesn't actually know anything about what it takes to design unique, professional (or unprofessional, if that's what you're looking for) websites that help you achieve your goals. We always do it custom, because it's more fun that way.</p>
                </section>
                <section className="white">
                    <h2>What are you looking for?</h2>  
                    <p>Some sites don't need to be "all that." Some sites need to be ridiculously amazing in every aspect. We'll work with you on your specific goals and budget in mind, creating everything from simple 1 page HTML sites, to adaptable, scalable HTML, WordPress, and other custom-built sites that can grow with you, and your business.</p>  
                </section>
                <section>
                    <h2>SOME OF OUR WORK</h2>
                    <ul className="ImageList">
                        <li className="ImageList"><a href="https://www.markwoodconsultingllc.com" target="_blank" rel="noreferrer" alt="markwood"><img alt="markwood" src={Markwood}></img></a></li>
                        <li className="ImageList"><a href="https://www.owlsroostmeltandmore.shop" target="_blank" rel="noreferrer" alt="Owl's Roost Wax Melts"><img alt="Owl's Roost Wax Melts" src={OwlsRoost}></img></a></li>
                        <li className="ImageList"><a href="https://www.masonayres.design/deprecated/myku.html" target="_blank" rel="noreferrer" alt="MYKU UX REDESIGN"><img alt="MYKU" src={MYKU}></img></a></li>
                        <li className="ImageList"><a href="https://www.jakobwiosk.com" target="_blank" rel="noreferrer" alt="Jakob Wiosk Videography"><img alt="Jakob Wiosk" src={JakobWiosk}></img></a></li>
                    </ul>
                </section>
                <section className="white">
                    <h2>THE PROCESS:</h2>
                    <div className="IconBox"><img src={webProcess1} alt="Web Process img 1"></img><h2 className='Arrow'>&gt;</h2> <img src={webProcess2} alt="Web Process img 2"></img> <h2 className='Arrow'>&gt;</h2><img src={webProcess3} alt="Web Process img 3"></img></div>
                    <div className="IconBox"><h3>THE CREATIVE PROCESS</h3><h3>PRODUCTION</h3><h3>FINAL DEPLOYMENT</h3> </div>
                    <div className="IconBox"><p>The creation stage is where all the magic is born. We'll work with you to get things headed in the right direction.</p> <p>The actual coding of the website: everything is coded and drafted line-by-line. No clunky, slow website builders here.</p> <p>Deployment is always the most important part, it's showtime. We can deploy the site in a way that works for you.</p></div>
                </section>
            </div>
            <BottomContent />
        </div>
    );    
};

export default WebDesign;