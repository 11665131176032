import React, { useEffect, useRef } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import Comp1 from '../resources/animations/Comp_1.json';
import Comp2 from '../resources/animations/Comp_2.json';
import Comp3 from '../resources/animations/Comp_3.json';
import Comp4 from '../resources/animations/Comp_4.json';
import Comp5 from '../resources/animations/Comp_5.json';
import Comp6 from '../resources/animations/Comp_6.json';

const Doodles = () => {
  const comp1Ref = useRef(null);
  const comp2Ref = useRef(null);
  const comp3Ref = useRef(null);
  const comp4Ref = useRef(null);
  const comp5Ref = useRef(null);
  const comp6Ref = useRef(null);

  const refs = [comp1Ref, comp2Ref, comp3Ref, comp4Ref, comp5Ref, comp6Ref];

  const getRandomDelay = () => Math.random() * 3000 + 5000; // Random delay between 5000ms and 8000ms

  useEffect(() => {
    refs.forEach((ref) => {
      const randomDelay = getRandomDelay(); // Generate a larger delay for each animation

      setTimeout(() => {
        if (ref.current) {
          ref.current.play(); // Start the animation
          setTimeout(() => {
            ref.current.pause(); // Pause after 2.8 seconds
          }, 2800); // Pause point
        }
      }, randomDelay); // Apply unique delay
    });
  }, []);

  return (
    <div className="doodles">
      <div className="angyboy">
        <Player
          ref={comp1Ref}
          autoplay={false}
          loop={false}
          src={Comp1}
          style={{ width: '200px', height: '200px' }}
        />
      </div>
      <div className="bigboy">
        <Player
          ref={comp2Ref}
          autoplay={false}
          loop={false}
          src={Comp2}
          style={{ width: '200px', height: '200px' }}
        />
      </div>
      <div className="pouty">
        <Player
          ref={comp3Ref}
          autoplay={false}
          loop={false}
          src={Comp3}
          style={{ width: '200px', height: '200px' }}
        />
      </div>
      <div className="birdy">
        <Player
          ref={comp4Ref}
          autoplay={false}
          loop={false}
          src={Comp4}
          style={{ width: '500px', height: '300px' }}
        />
      </div>
      <div className="stumpy">
        <Player
          ref={comp5Ref}
          autoplay={false}
          loop={false}
          src={Comp5}
          style={{ width: '300px', height: '300px' }}
        />
      </div>
      <div className="bumpy">
        <Player
          ref={comp6Ref}
          autoplay={false}
          loop={false}
          src={Comp6}
          style={{ width: '300px', height: '300px' }}
        />
      </div>
    </div>
  );
};

export default Doodles;
